<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <button class="uk-button uk-button-default uk-margin-bottom" @click="goBack">
                <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
                <span>Back</span>
            </button>

            <LoaderPage v-if="isLoading"/>
            <template v-else>
                <div class="uk-card uk-shadow uk-card-default uk-padding-small">
                    <div class="uk-margin-top uk-grid">
                        <div class="uk-width-1-6 uk-flex uk-flex-middle uk-flex-center uk-first-column">
                            <img 
                                :src="userProfilePicture" 
                                class="user-photo bg-transparant" 
                                alt="Profile picture"
                            />
                        </div>
                        <div class="uk-width-expand">
                            <div class="uk-flex uk-flex-middle uk-flex-left">
                                <p class="uk-margin-remove user-name">{{ userFullname }}</p>
                                <span :class="[userIsVip ? 'uk-badge badge-premium' : 'uk-badge badge-free']">
                                    <img 
                                        :src="`${baseUrlImage}/premium-outline.svg`" 
                                        alt="Membership badge"
                                    />
                                    {{ userIsVip ? 'Premium' : 'Free' }}
                                </span>
                            </div>
                            
                            <div class="uk-margin-top uk-child-width-1-2 uk-grid">
                                <div class="uk-first-column">
                                    <p class="title-field uk-margin-remove uk-padding-remove">
                                        Email: <span class="text-data">{{ userEmail }}</span>
                                    </p>
                                    <p class="title-field uk-margin-remove uk-padding-remove">
                                        Phone: <span class="text-data">{{ userPhoneNumber }}</span>
                                    </p>
                                    <p class="title-field uk-margin-remove uk-padding-remove">
                                        Roles Type: <span class="text-data">{{ userRoleType }}</span>
                                    </p>
                                    <p class="title-field uk-margin-remove uk-padding-remove" v-if="userIsPartner">
                                        Employee ID: <span class="text-data">{{ userEmployeeIds }}</span>
                                    </p>
                                    <p class="title-field uk-margin-remove uk-padding-remove" v-if="userIsPartner">
                                        Company: <span class="text-data">{{ userCompanyNames }}</span>
                                    </p>
                                    <p class="title-field uk-margin-remove uk-padding-remove" v-if="!userIsPartner">
                                        User ID: <span class="text-data">{{ userId }}</span>
                                    </p>
                                </div>
                                <div>
                                    <p class="title-field uk-margin-remove uk-padding-remove">
                                        Earliest Activation: <span class="text-data">{{ userEarliestActivate }}</span>
                                    </p>
                                    <p class="title-field uk-margin-remove uk-padding-remove">
                                        Latest Activation: <span class="text-data">{{ userLastActivate }}</span>
                                    </p>
                                    <p class="title-field uk-margin-remove uk-padding-remove">
                                        Current Expiration: 
                                        <span class="text-data">
                                            {{ userCurrentExpired }} 
                                            <span class="error-text" v-if="userIsExpired">(Expired)</span>
                                        </span>
                                    </p>
                                    <p class="title-field uk-margin-remove uk-padding-remove">
                                        Latest Payment Method: <span class="text-data">{{ userLastestPaymentMethod }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Transaction content list -->
                <div class="uk-card uk-card-default">
                    <p class="uk-padding-small uk-text-bold">Premium Membership History</p>
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-striped uk-table-middle">
                            <thead>
                                <tr>
                                    <th class="uk-text-left uk-text-nowrap">No.</th>
                                    <th class="uk-text-left uk-text-nowrap">Transaction ID</th>
                                    <th class="uk-text-left uk-text-nowrap">Activation Date</th>
                                    <th class="uk-text-left uk-text-nowrap">Canceled Date</th>
                                    <th class="uk-text-left uk-text-nowrap">Expiration Date</th>
                                    <th class="uk-text-left uk-text-nowrap">Product Name</th>
                                    <th class="uk-text-left uk-text-wrap">Payment Method</th>
                                    <th class="uk-text-left uk-text-nowrap">Company</th>
                                    <th class="uk-text-left uk-text-nowrap uk-width-small">Total Amount</th>
                                    <th class="uk-text-left uk-text-nowrap">Status</th>
                                </tr>
                            </thead>
                            <loading-table v-if="tableLoading" :colspan="8" />
                            <template v-else>
                                <tbody v-if="transactionData.totalDocs > 0">
                                    <template v-for="(tx, index) in transactionData.docs">
                                        <tr :key="index">
                                            <td class="uk-text-left uk-text-nowrap" :class="[tx.is_active ? '' : 'uk-text-danger']">
                                                {{ autoIncrementNumberPagination({ pagingCounter: transactionData.pagingCounter, index }) }}
                                            </td>
                                            <td class="uk-text-left uk-text-nowrap" :class="[tx.is_active ? '' : 'uk-text-danger']">
                                                {{ tx.transaction_id || '-' }}
                                            </td>
                                            <td class="uk-text-left uk-text-nowrap" :class="[tx.is_active ? '' : 'uk-text-danger']">
                                                {{ tx.disbursed_at || '-' }}
                                            </td>
                                            <td class="uk-text-left uk-text-nowrap" :class="[tx.is_active ? '' : 'uk-text-danger']">
                                                {{ tx.unsubscribe_at || '-' }}
                                            </td>
                                            <td class="uk-text-left uk-text-nowrap" :class="[tx.is_active ? '' : 'uk-text-danger']">
                                                {{ tx.expired_at || '-' }}
                                            </td>
                                            <td class="uk-text-left uk-text-nowrap" :class="[tx.is_active ? '' : 'uk-text-danger']">
                                                {{ tx.product_name || '-' }}
                                            </td>
                                            <td class="uk-text-left uk-text-nowrap" :class="[tx.is_active ? '' : 'uk-text-danger']">
                                                {{ tx.payment_method || '-' }}
                                            </td>
                                            <td class="uk-text-left uk-text-nowrap" :class="[tx.is_active ? '' : 'uk-text-danger']">
                                                {{ tx.company_name || '-' }}
                                            </td>
                                            <td class="uk-text-left uk-text-nowrap" :class="[tx.is_active ? '' : 'uk-text-danger']">
                                                {{ currentyFormat({ number: tx.amount || 0 }) }}
                                            </td>
                                            <td class="uk-text-left uk-text-nowrap" :class="[tx.is_active ? '' : 'uk-text-danger']">
                                                {{ tx.is_active ? 'Subscribe' : 'Canceled' }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <empty-table v-else :colspan="8" />
                            </template>
                        </table>
                    </div>
                    <pagination
                        :total-data="transactionData.totalDocs"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    />
                </div>
                <!-- End transaction content list -->
            </template>
        </template>
        <NoInternetConnection v-else />
    </div>
</template>
<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions } from 'vuex';
import {
    notificationDanger
} from '@/utils/notification';
import formatter from '@/utils/formatter';

export default {
    name: 'MembershipDetail',
    components : {
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
    },
    data() {
        return {
            isLoading: false,
            tableLoading: false,
            internetConnection: true,
            baseUrlImage: PREFIX_IMAGE,
            defaultImage : `${PREFIX_IMAGE}/avatar.svg`,
            userData: {},
            transactionData: {
                docs: [],
                pagingCounter: 0,
                totalDocs: 0
            },
            meta: {
                limit: 10,
                page: 1,
            }
        };
    },
    computed: {
        routeParamsUserId() {
            return this.$route && this.$route.params && this.$route.params.user_id || null;
        },
        userId() {
            return this.userData && this.userData._id || '-';
        },
        userProfilePicture() {
            return this.userData && this.userData.photo_url && this.userData.photo_url.file_url || this.defaultImage;
        },
        userFullname() {
            return this.userData && this.userData.fullname || '-';
        },
        userEmail() {
            return this.userData && this.userData.email || '-';
        },
        userPhoneNumber() {
            return this.userData && this.userData.phone_number || '-';
        },
        userEmployeeIds() {
            return Array.isArray(this.mapUserEmployeeIds) && this.mapUserEmployeeIds.length > 0 ? this.arrayPreview({ data: this.mapUserEmployeeIds, limit: 3 }) : '-';
        },
        userCompanyNames() {
            return Array.isArray(this.mapUserCompanyNames) && this.mapUserCompanyNames.length > 0 ? this.arrayPreview({ data: this.mapUserCompanyNames, limit: 3 }) : '-';
        },
        userEarliestActivate() {
            return this.userData && this.userData.earliest_activate ? this.datePreview({ text: this.userData.earliest_activate }) : '-';
        },
        userLastActivate() {
            return this.userData && this.userData.last_activate ? this.datePreview({ text: this.userData.last_activate }) : '-';
        },
        userCurrentExpired() {
            return this.userData && this.userData.current_expired ? this.datePreview({ text: this.userData.current_expired }) : '-';
        },
        userLastestPaymentMethod() {
            return this.userData && this.userData.last_payment_method || '-';
        },
        userRoleType() {
            return this.userData && this.userData.role_type || '-';
        },
        userIsVip() {
            return this.userData && this.userData.is_vip || false;
        },
        userIsExpired() {
            return this.userData && this.userData.is_expired || false;
        },
        userIsPartner() {
            return this.userData && Array.isArray(this.userData.partner_ids) && this.userData.partner_ids.length > 0 ? true : false;
        },
        mapUserEmployeeIds() {
            return this.userData && Array.isArray(this.userData.employee_ids) && this.userData.employee_ids.length > 0 ? this.userData.employee_ids.map((item) => ({ value: item || '-' })) : [];
        },
        mapUserCompanyNames() {
            return this.userData && Array.isArray(this.userData.company_names) && this.userData.company_names.length > 0 ? this.userData.company_names.map((item) => ({ value: item || '-' })) : [];
        }
    },
    async mounted(){
        try {
            if (!navigator.onLine) return this.internetConnection = false;
            this.isLoading = true;
            await Promise.all([
                this.setTransactionList(),
                this.setUserProfile()
            ]);
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods:{
        ...mapActions({
            getMembershipDetail : 'membershipV4/getMembershipDetail',
            getMembershipDetailList : 'membershipV4/getMembershipDetailList'
        }),
        async setUserProfile(){
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                const response = await this.getMembershipDetail(this.routeParamsUserId);
                if(response && response.data && response.data.status === 'OK' && response.data.result){
                    const result = response.data.result;
                    this.userData = result || null;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setTransactionList(){
            try {
                if (!navigator.onLine) return this.internetConnection = false;
                const response = await this.getMembershipDetailList(this.routeParamsUserId, this.meta);
                if (response && response.data && response.data.status === 'OK' && response.data.result) {
                    const result = response.data.result;
                    const docs = result && Array.isArray(result.docs) && result.docs.length > 0 ? result.docs : [];
                    const pagingCounter = result && result.pagingCounter || 0;
                    const totalDocs = result && result.totalDocs || 0;
                    
                    this.transactionData.docs = docs;
                    this.transactionData.pagingCounter = pagingCounter;
                    this.transactionData.totalDocs = totalDocs;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        changeLimit(e) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            if (!navigator.onLine) return this.internetConnection = false;
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        datePreview({ text }){
            let string = '';
            string = formatter.dateComplete(text);
            return string;
        },
        autoIncrementNumberPagination({ pagingCounter, index }) {
            return formatter.autoIncrementNumberPagination({ pagingCounter, index });
        },
        currentyFormat({ number }) {
            return formatter.numberFormat(number);
        },
        goBack() {
            this.$router.back();
        },
        arrayPreview({ data, limit }) {
            return formatter.arrayPreview({ data, limit });
        }
    }
};
</script>

<style scoped>
    .uk-shadow {
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }
    .user-photo {
        text-align: center;
        border-radius: 50%;
        color: #fff;
        width: 100%;
        height: 100%;
        min-width: 113px;
        max-width: 113px;
        min-height: 113px;
        max-height: 113px;
        object-fit: cover;
        object-position: center;
    }
    .user-name {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }
    .title-field {
        font-weight: 600;
        size: 14px;
        line-height: 21px;
        margin-bottom: 5px;
    }
    .text-data {
        font-weight: 400;
    }
    .error-text {
        color: #FF0000;
    }
    .badge-premium {
        background: #FFD700;
        border-radius: 40px;
        margin-left: 10px;
        width: 113px;
        height: 32px;
        font-size: 14px;
        line-height: 21px;
    }
    .badge-free {
        background: #B5B5B5;
        border-radius: 40px;
        margin-left: 10px;
        width: 80px;
        height: 32px;
        font-size: 14px;
        line-height: 21px;
    }
</style>
